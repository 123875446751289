const users = {
    name: 'Users/UsersPage',
    path: '/users',
    icon: 'mdi-account-group',
    label: 'Users'
};

const dashboard = {
    name: 'Dashboard/DashboardPage',
    path: '/',
    icon: 'mdi-view-dashboard',
    label: 'Dashboard'
};

const profile = {
    name: 'Dashboard/UserProfilePage',
    path: '/profile',
    icon: 'mdi-account',
    label: 'Profile'
};

const horizon = {
    name: 'Horizon',
    path: '/horizon',
    icon: 'mdi-sun-angle',
    label: 'Horizon',
    private: true,
    privateLabel: 'SA'
};

const pulse = {
    name: 'Pulse',
    path: '/pulse',
    icon: 'mdi-pulse',
    label: 'Pulse',
    private: true,
    privateLabel: 'SA'
};

const telescope = {
    name: 'Telescope',
    path: '/telescope',
    icon: 'mdi-telescope',
    label: 'Telescope',
    private: true,
    privateLabel: 'SA'
};

export const superAdmin = _ => {
    return [
        dashboard,
        users,
    ];
};

export const bottomNavRoutes = _ => {
    return [
        profile,
        horizon,
        pulse,
        telescope,
    ];
};
