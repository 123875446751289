
<template>
<LoginContainer>
    <template #title>Register</template>
    <form @submit.prevent="register">
        <StyledInput
            v-model="form.first_name"
            name="first_name"
            :error-message="getError('first_name')"
            placeholder="First Name"
            label="First Name" />
        <StyledInput
            v-model="form.last_name"
            name="last_name"
            :error-message="getError('last_name')"
            placeholder="Last Name"
            label="Last Name" />
        <StyledInput
            v-model="form.email"
            name="email"
            :error-message="getError('email')"
            placeholder="Email"
            label="Email" />
        <StyledMask
            v-model="form.phone"
            :mask="['###-###-####']"
            label="Phone Number"
            name="phone"
            placeholder="###-###-####" />
        <StyledInput
            v-model="form.password"
            type="password"
            class="password"
            name="password"
            :error-message="getError('password')"
            placeholder="******************"
            label="Password" />
        <StyledInput
            v-model="form.password_confirmation"
            type="password"
            class="password"
            name="password_confirmation"
            :error-message="getError('password_confirmation')"
            placeholder="******************"
            label="Password Confirmation" />
        <StyledButton
            :full="true"
            type="submit"
            class="mb-4">
            Register
        </StyledButton>
    </form>
</LoginContainer>
</template>

<script>
import axios from 'axios';
import LoginContainer from '@/components/Auth/LoginContainer';

export default {
    components: {
        LoginContainer,
    },

    props: {
        errors: {
            type: Object,
            default: _ => ({})
        }
    },

    data: _ => ({
        form: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
        },
    }),

    computed: {
        getError() {
            return selector => {
                const error = this.errors[selector];
                return error || '';
            };
        }
    },

    methods: {
        async register() {
            try {
                await axios.get('/sanctum/csrf-cookie');
                axios.post('/api/v1/register/', this.form).then(_ => {
                    this.$inertia.visit('/login');
                });
            } catch (e) {
                //
            }
        },
    }
};
</script>
