<script setup>
import { computed } from 'vue';

const props = defineProps({
    actions: {
        type: Array,
        default: () => ([])
    },

    item: {
        type: Object,
        default: () => ({})
    }
});

const filteredActions = computed(() => {
    return props.actions.filter(action => {
        if (typeof action.isVisible === 'function') {
            return action.isVisible(props.item);
        }
        return action.isVisible !== undefined ? action.isVisible : true;
    });
});

const item = computed(() => props.item);
</script>
<template>
<div>
    <template v-if="filteredActions.length === 1">
        <StyledButton
            full
            @click="filteredActions[0].action(item)">
            {{ filteredActions[0].title }} <FontAwesomeIcon :icon="filteredActions[0].icon" class="ml-2" />
        </StyledButton>
    </template>
    <v-btn
        v-else
        color="primary"
        variant="outlined">
        <FontAwesomeIcon
            icon="ellipsis-h"
            class="text-primary text-xl" />
        <v-menu activator="parent">
            <v-list>
                <v-list-item
                    v-for="(action, index) in filteredActions"
                    :key="index"
                    :value="index"
                    @click="action.action(item)">
                    <template #prepend>
                        <VIcon
                            v-if="action.icon.includes('mdi-')"
                            :icon="action.icon" />
                        <FontAwesomeIcon
                            v-else
                            class="fa-fw mr-4"
                            :icon="action.icon" />
                    </template>
                    <v-list-item-title> {{ action.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-btn>
</div>
</template>
