<script setup>
import { ref, watch, toRaw } from 'vue';
import { router } from '@inertiajs/vue3';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const form = ref({
    password: '',
    securityQuestions : []
});

const userStore = useUserStore();
const optionsStore = useOptionsStore();
const creating = ref(true);
const { securityQuestionTemplates } = storeToRefs(optionsStore);

watch (() => userStore.profile, () => {
    creating.value = userStore.profile.security_questions.length === 0;
    if(creating.value){
        for(let i = 0; i < userStore.profile.security_questions_number; i++){
            form.value.securityQuestions.push({
                security_question_template_id: null,
                question: null,
                answer: null,
                show_custom: false
            });
        }
        return;
    }
    userStore.profile.security_questions.forEach( item => item.show_custom = item.security_question_template_id === null );
    form.value = toRaw({ securityQuestions : [...userStore.profile.security_questions], password: '' });
});

const save = () => {
    const action = creating.value ? userStore.storeSecurityQuestions : userStore.updateSecurityQuestions;
    action({
        ...form.value
    }).then(() => {
        if(creating.value){
            router.visit('/2fa/setup');
        }
    });
};
const switchQuestion = (idx)=>{
    if(!form.value.securityQuestions[idx].show_custom){
        form.value.securityQuestions[idx].security_question_template_id = null;
    }
    else{
        form.value.securityQuestions[idx].question = null;
    }

    form.value.securityQuestions[idx].answer = null;
    form.value.securityQuestions[idx].show_custom = !form.value.securityQuestions[idx].show_custom;
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        {{ creating ?  'Create' : 'Edit' }} Security Questions
    </template>

    <form class="flex flex-col gap-4">
        <div v-for="(question, idx) in form.securityQuestions"  :key="idx">
            <div v-if="form.securityQuestions[idx]" class="flex flex-row gap-2">
                <StyledDropdown
                    v-if="!form.securityQuestions[idx].show_custom"
                    v-model="form.securityQuestions[idx].security_question_template_id"
                    item-title="question"
                    item-value="id"
                    class="basis-[90%]"
                    :name="`securityQuestions.${idx}.security_question_template_id`"
                    :clearable="false"
                    :options="securityQuestionTemplates"
                    placeholder="Please Select"
                    :label="`Question #${(Number(idx)+1)}:`" />
                <StyledInput
                    v-else
                    v-model="form.securityQuestions[idx].question"
                    :name="`securityQuestions.${idx}.question`"
                    :label="`Custom Question #${(Number(idx)+1)}:`"
                    placeholder="Answer" />
                <StyledButton
                    class="basis-[15%]"
                    color="primary"
                    @click="switchQuestion(idx)">
                    {{ form.securityQuestions[idx].show_custom ? 'Template' :'Custom' }}
                </StyledButton>
            </div>
            <StyledPassword
                v-if="form.securityQuestions[idx]"
                v-model="form.securityQuestions[idx].answer"
                :name="`securityQuestions.${idx}.answer`"
                class="mt-2"
                :label="`Answer Question #${(Number(idx)+1)}:`"
                placeholder="Answer" />
        </div>
        <StyledPassword
            v-model="form.password"
            label="Password"
            name="password"
            autocomplete="password"
            placeholder="Password" />

        <StyledSaveButton @save="save" @cancel="$inertia.visit('/profile')" />
    </form>
</MainContentLayout>
</template>
