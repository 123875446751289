<script>
import { format, parse } from 'date-fns';

export function useDateFormatter() {
    const formatDateTime = (date, formatString = 'MM/dd/yyyy h:mm a') => {
        return date ? format(new Date(date), formatString) : '';
    };

    const formatDate = (date, formatString = 'MM/dd/yyyy') => {
        return date ? format(new Date(date), formatString) : '';
    };

    const parseDate = (dateString) => {
        return parse(dateString, 'yyyy-MM-dd', new Date());
    };

    return {
        formatDateTime,
        formatDate,
        parseDate
    };
}
</script>
