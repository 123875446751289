import { ref, computed } from 'vue';
import { useDateFormatter } from '@/composables/dateFormatter';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore = defineStore('user', () => {
    const profile = ref(null);
    const all = ref(null);
    const navLayoutOverride = ref(false);
    const unverified = ref(null);
    const locale = ref('en');

    const { formatDateTime } = useDateFormatter();

    const lastLogin = computed(() => {
        if (!profile.value?.last_login_log) {
            return null;
        }
        const log = profile.value.last_login_log;
        const time = formatDateTime(log.created_at);
        const ip = log.ip;
        const location = log.city ? `${log.city}, ${log.country}` : 'Unknown Location';
        return `${time} from ${ip} (${location})`;
    });

    const reset = () => {
        profile.value = null;
        all.value = null;
        navLayoutOverride.value = false;
    };

    const actions = {
        getUserRoleString(user) {
            return user?.roles?.map(role => role.display_name).at(0);
        },
        searchUsers: (params) => axios.get('/api/v1/users', { params }),
        storeUser: (params) => axios.post('/api/v1/users', params),
        updateUser: (params) => axios.patch('/api/v1/users/' + params.hash, params),
        updateProfile: (params) => axios.patch('/api/v1/profile', params),
        destroyUser: (params) => axios.delete('/api/v1/users/' + params.hash),
        storeSecurityQuestions: (params) => axios.post('/api/v1/profile/security-questions', params),
        updateSecurityQuestions: (params) => axios.patch('/api/v1/profile/security-questions', params),
    };

    const setLocale = (option) => {
        locale.value = option;
        window.axios.post('/set-locale', { locale: option });
    };

    return {
        profile,
        unverified,
        all,
        navLayoutOverride,
        lastLogin,
        locale,
        setLocale,
        reset,
        ...actions,
    };
});
