<template>
<MainContentLayout>
    <Head title="Dashboard" />

    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        Dashboard
    </template>
</MainContentLayout>
</template>

<script>
import { Head } from '@inertiajs/vue3';

export default {
    setup() {
        return { Head };
    }
};

</script>
