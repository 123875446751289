import { defineStore } from 'pinia';

export const useOptionsStore = defineStore('options', {
    state: () => ({
        roles: [],
        statuses: [],
        provinces: [],
        preferred_contact_methods: [],
        phone_types: [],
        twoFactorMethods: [],
        securityQuestionTemplates: [],
        securityCheckpoint: false,
    }),
    actions: {
        reset() {
            this.$reset();
        },
    }
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useOptionsStore.acceptHMRUpdate);
}
