<template>
<div class="flex gap-4 justify-end">
    <StyledButton
        v-if="!hideCancel && !modal"
        variant="outlined"
        :label="$t('label.cancel')"
        @click="$emit('cancel')">Cancel</StyledButton>
    <StyledButton :label="$t('label.save')" @click="$emit('save')">Save</StyledButton>
</div>
</template>

<script>
export default {
    props: {
        hideCancel: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: false
        }
    },

    emits: ['save', 'cancel'],
};
</script>
