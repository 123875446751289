<script setup>
import { useUserStore } from '@/stores/user';
import { loadLanguageAsync } from 'laravel-vue-i18n';
import { ref, watch } from 'vue';

const { setLocale } = useUserStore();
const language = ref('en');

watch(language, (lang) => {
    loadLanguageAsync(lang);
    setLocale(lang);
});
</script>
<template>
<div class="login-container" :class="false ? 'pt-6 md:mt-0' : 'h-screen'">
    <div class="w-full flex flex-col items-center justify-center z-10">
        <div class="bg-white shadow-md rounded-2xl px-8 md:min-w-120 py-8 mb-4 mx-4 md:mx-0">
            <div class="flex justify-between items-center gap-8">
                <img class="w-64 mb-4" src="/images/logo.png">

                <v-btn-toggle
                    v-model="language"
                    density="compact"
                    variant="flat"
                    color="primary"
                    mandatory
                    group>
                    <v-btn value="en">
                        English
                    </v-btn>

                    <v-btn value="fr">
                        Français
                    </v-btn>
                </v-btn-toggle>
            </div>

            <h1 class="my-4 h6 font-mono text-black">
                <slot name="title" />
            </h1>
            <slot />
        </div>
    </div>
</div>
</template>
