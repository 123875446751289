<script setup>
import { toRaw, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { useForm } from 'laravel-precognition-vue';

const form = useForm('patch', '/api/v1/profile', {
    email: null,
    first_name: null,
    last_name: null,
    phone_number: null,
    password: null,
    password_confirmation: null,
    navigation: 'side',
});

const userStore = useUserStore();

watch (() => userStore.profile, () => {
    form.setData(toRaw({ ...userStore.profile }));
});

const cancel = () => {
    window.history.go(-1);
};

const save = () => {
    form.submit();
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        Edit Profile
    </template>
    <template #actions>
        <StyledButton
            size="small"
            variant="outlined"
            @click="$inertia.visit('/profile/security-questions')">
            <FontAwesomeIcon
                icon="lock"
                class="mr-1" />
            Change Security Questions/Answers
        </StyledButton>
    </template>
    <form class="grid grid-cols-1 gap-2">
        <StyledInput
            v-model="form.first_name"
            label="First Name"
            name="first_name"
            placeholder="First Name"
            @change="form.validate('first_name')" />
        <StyledInput
            v-model="form.last_name"
            label="Last Name"
            name="last_name"
            placeholder="Last Name"
            @change="form.validate('last_name')" />
        <StyledInput
            v-model="form.email"
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            @change="form.validate('email')" />
        <StyledInput
            v-model="form.password"
            label="Password"
            name="password"
            autocomplete="new-password"
            placeholder="Optional"
            type="password" />
        <StyledInput
            v-model="form.password_confirmation"
            label="Password Confirmation"
            name="password_confirmation"
            type="password" />
        <StyledSaveButton @save="save" @cancel="cancel" />
    </form>
</MainContentLayout>
</template>
