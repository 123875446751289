<script setup type="module">
import { ref, inject, onMounted, onUnmounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toastification';

const toast = useToast();
const params = ref({
    term: '',
    role: null,
    status: 'All'
});

const userStore = useUserStore();
const { searchUsers, destroyUser, getUserRoleString } = userStore;
const { all } = storeToRefs(userStore);

const optionsStore = useOptionsStore();
const { roles } = storeToRefs(optionsStore);

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchUsers(params.value);
};

const deleteUser = async (user) => {
    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm User Removal',
            prompt: 'Delete ' + user.first_name + ' ' + user.last_name + '?'
        }
    });

    if (confirm) {
        destroyUser({
            hash: user.hash
        }).then(_ => {
            search();
        });
    }
};

const modals = inject('modals');
const openModal = (modal, mode = 'Create', user = null) => {
    if (mode == 'Edit') {
        modals.show(modal, {
            props: {
                user: {
                    ...user,
                    role: {
                        value: user.roles[0].name,
                        label: user.roles[0].display_name
                    },
                    set_password: false,
                    send_password: false
                },
                mode: 'Edit'
            }
        }).then(_ => {
            search();
        });
    } else {
        modals.show(modal).then(_ => {
            search();
        });
    }
};

onMounted(() => {
    window.Echo.private('test-web-sockets')
        .listen('TestWebSockets', (_) => {
            toast.success('Web socket event received!');
        });
});

onUnmounted(() => {
    window.Echo.leave('test-web-sockets');
});

</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        Users
    </template>
    <template #actions>
        <StyledButton @click="openModal('UsersModal')">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            Add User
        </StyledButton>
    </template>
    <div class="grid md:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.role"
            name="role"
            item-title="display_name"
            item-value="name"
            :options="roles"
            label="Role"
            @input="search" />
    </div>

    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.last_first_name'), key: 'full_name', value: item => `${item.last_name}, ${item.first_name}` },
            { title: $t('label.role'), key: 'role', value: item => getUserRoleString(item) },
            { title: $t('label.email'), key: 'email'},
            { title: $t('label.active'), key: 'is_active' },
        ]"
        :actions="[
            { title: 'Edit', icon: 'user-edit', action: (item) => openModal('UsersModal', 'Edit', item) },
            { title: 'Delete', icon: 'user-times', action: (item) => deleteUser(item) },
        ]"
        @table-update="tableUpdate">
        <template #[`item.is_active`]="{ item }">
            <vChip :color="item ? 'green' : 'red'">{{ item ? 'Yes' : 'No' }}</vChip>
        </template>
    </DataTable>

</MainContentLayout>
</template>

