<script setup>
import { inject, computed, watch, ref } from 'vue';
import { useScreenSize } from '@/composables/screenSize';
import DataTableActions from '@/components/DataTableActions.vue';
import { trans } from 'laravel-vue-i18n';

const { mdScreen } = useScreenSize();

const props = defineProps({
    data: {
        type: Object,
        required: true
    },

    headers: {
        type: Array,
        required: true
    },

    actions: {
        type: Array,
        default: () => ([])
    },

    returnObject: {
        type: Boolean,
        default: true
    },

    showSelect: {
        type: Boolean,
        default: false
    },

    selectStrategy: {
        type: String,
        default: 'page'
    }
});

const emit = defineEmits(['table-update', 'update:modelValue']);
const isLoading = inject('isLoading');

const defaultItemsPerPage = 10;

const selected = ref([]);
const tableItems = computed(() => props.data.data);
const totalItems = computed(() => props.data?.total ?? 0);
const itemsPerPage = props.data?.per_page ? ref(props.data.per_page) : ref(defaultItemsPerPage);

const mobileItemsPerPage = 5;
const mobilePage = ref(1);
const mobilePages = computed(() => Math.ceil(totalItems.value / mobileItemsPerPage));

const loadItems = ({ page, itemsPerPage, sortBy }) => {
    // Map the sortBy array to a array of [key => order] objects
    const mapped = sortBy.reduce((acc, column) => {
        acc[column.key] = column.order;
        return acc;
    }, {});

    const updateParams = {
        page,
        sortBy: mapped,
        itemsPerPage,
    };

    emit('table-update', updateParams);
};

const loadMobileItems = (page) => {
    const updateParams = {
        page,
        itemsPerPage: mobileItemsPerPage,
    };

    emit('table-update', updateParams);

    // Then scroll to the top of the page smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

watch(() => selected.value, (val) => {
    emit('update:modelValue', val);
});

watch(mdScreen, () => {
    if (!mdScreen.value) {
        loadItems({ page: 1, itemsPerPage: itemsPerPage.value, sortBy: [] });
    }
});

const hasActions = computed(() => props.actions?.length > 0);
const headers = computed(() => {
    const headers = props.headers.map(header => {
        return {
            ...header,
        };
    });

    if (hasActions.value) {
        headers.push({
            key: 'actions',
            title: trans('label.actions'),
            sortable: false,
            width: '100px'
        });
    }

    return headers;
});

</script>
<template>
<div v-if="data">
    <div v-if="mdScreen" class="space-y-4">
        <vCard
            v-for="(item, idx) in tableItems.slice(0, mobileItemsPerPage)"
            :key="idx"
            :title="item.name"
            outlined>
            <vList>
                <div
                    v-for="attr in headers"
                    :key="attr.key"
                    class="px-5 mb-3">
                    <template v-if="$slots[`item.${attr.key}`]">
                        <span class="font-bold">{{ attr.title }}</span>: <slot :name="`item.${attr.key}`" v-bind="{item}" />
                    </template>
                    <template v-else-if="attr.key !== 'actions'">
                        <span class="font-bold">{{ attr.title }}</span>: {{ attr.value ? attr.value(item) : item[attr.key] }}
                    </template>
                </div>
                <div v-if="hasActions" class="grid grid-cols-2 gap-2 mt-2 px-5">
                    <StyledButton
                        v-for="(action, actionIdx) in actions"
                        :key="actionIdx"
                        full
                        size="small"
                        @click="action.action(item)">
                        {{ action.title }} <template v-if="!action.icon.includes('mdi-')"><FontAwesomeIcon :icon="action.icon" class="ml-2" /></template><template v-else><v-icon :icon="action.icon" class="ml-2" color="white" /></template>
                    </StyledButton>
                </div>
            </vList>
        </vCard>
        <v-pagination
            v-model="mobilePage"
            :length="mobilePages"
            density="compact"
            @update:modelValue="loadMobileItems" />
    </div>
    <vDataTableServer
        v-else
        v-model="selected"
        :items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="tableItems"
        :loading="isLoading"
        :show-select="showSelect"
        item-value="name"
        :select-strategy="selectStrategy"
        :return-object="returnObject"
        @update:options="loadItems">
        <template v-for="(_, name) in $slots" #[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
        <template v-if="hasActions" #[`item.actions`]="{ item }">
            <DataTableActions :actions="actions" :item="item" />
        </template>
    </vDataTableServer>
</div>
</template>
<style>
/* Target only the v-select in the data table pagination */
.v-data-table-footer__items-per-page .v-field__clearable {
    display: none;
}
</style>
