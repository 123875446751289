
<script setup>
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import LoginContainer from '@/components/Auth/LoginContainer';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import { router } from '@inertiajs/vue3';

const optionsStore = useOptionsStore();
const twoFactorMethods = storeToRefs(optionsStore).twoFactorMethods;

const userStore = useUserStore();
const { unverified: profile } = storeToRefs(userStore);

const form = ref({
    two_factor_method: null,
    phone_id: null,
});

const checkSelected = (value) => {
    const methodId = form.value.two_factor_method;
    return twoFactorMethods.value.find(x => x.id === methodId)?.name === value;
};

const isEmailSelected = computed(() => checkSelected('Email'));
const isPhoneSelected = computed(() => checkSelected('Phone'));

const disabledSubmit = computed(() => {
    return !form.value.two_factor_method
        || (isPhoneSelected.value && !profile.value.masked_phone_numbers?.length);
});

const save2faMethod = () => {
    router.post('/2fa/set-method', form.value);
};
</script>
<template>
<LoginContainer>
    <form @submit.prevent="save2faMethod">
        <div class="grid grid-cols-1 gap-4 mt-5">
            <div class="text-lg">Two Factor Authentication Setup</div>
            <StyledDropdown
                v-model="form.two_factor_method"
                :options="twoFactorMethods"
                name="two_factor_method"
                item-title="name"
                item-value="id"
                label="Two Factor Method" />

            <StyledInput
                v-if="isEmailSelected"
                :model-value="profile.masked_email"
                name="email"
                disabled
                label="Email Address" />

            <StyledDropdown
                v-if="isPhoneSelected"
                v-model="form.phone_id"
                :options="profile.masked_phone_numbers"
                name="phone_id"
                item-title="number"
                item-value="id"
                label="Phone Number" />
        </div>

        <div class="flex flex-col">
            <StyledButton
                full
                :disabled="disabledSubmit"
                type="submit"
                class="mb-4">Save</StyledButton>
        </div>
    </form>
</LoginContainer>
</template>
