<script setup>
import { ref, inject } from 'vue';
import { Head } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import StyledCluster from '@/components/LayoutCompositions/StyledCluster.vue';

const params = ref({
    name: '',
    name2: '',
    password: '',
    dropdown: null,
    dropdown2: null,
    dropdown3: null,
    dropdown4: null,
    dropdown5: null,
    checkbox: false,
    is_active: false,
    textarea: '',
    currency: 0,
    datepicker: null,
    datepicker2: null,
    search: '',
    radio: null,
    file: null,
    file2: null,
    mask: '',
    mask2: '',
    effective_date: null
});

const toast = useToast();
const modals = inject('modals');

const save = () => {
    toast.success('Saved!');
};

const openModal = () => {
    modals.show('UsersModal').then(_ => {
        //do sum ting
    });
};
</script>

<template>
<MainContentLayout>
    <Head title="Dashboard" />

    <template #breadcrumb> <BreadCrumb /></template>
    <template #title>
        Dashboard
    </template>

    <form class="md:grid md:grid-cols-2 gap-4" @submit.prevent="save">
        <StyledInput
            v-model="params.name"
            name="name"
            density="compact"
            counter="30"
            label="Compact Density Text Input" />
        <StyledInput
            v-model="params.name2"
            color="primary"
            name="name2"
            counter="30"
            label="Text Input With Counter And Primary Color" />
        <StyledPassword
            v-model="params.password"
            name="password"
            label="Simple Password" />
        <StyledPassword
            v-model="params.password"
            name="password"
            icon-show="mdi-eye-outline"
            icon-hide="mdi-emoticon"
            label="Simple Password Different Icons" />
        <StyledCheckbox
            v-model="params.checkbox"
            name="name"
            label="Check box" />
        <StyledDropdown
            v-model="params.dropdown"
            name="dropdown"
            :options="['test', 'test2']"
            label="Simple Dropdown" />

        <StyledDropdown
            v-model="params.dropdown2"
            name="dropdown"
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            label="Object Dropdown" />
        <StyledDropdown
            v-model="params.dropdown3"
            name="dropdown"
            multiple
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            label="Multiple Object Dropdown" />
        <StyledDropdown
            v-model="params.dropdown4"
            name="dropdown"
            multiple
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            label="Multiple Object Dropdown" />
        <StyledDropdown
            v-model="params.dropdown5"
            name="dropdown"
            multiple
            chips
            item-title="title"
            item-value="id"
            return-object
            hint="What are the target regions"
            persistent-hint
            :options="[{id: 'test', title: 'Test' }, {id: 'test2', title: 'Test 2' }]"
            label="Multiple Object Dropdown helper text with chips, Custom value and label" />
        <StyledRoundSwitch
            v-model="params.is_active"
            label="Is Active"
            color="primary"
            inset
            name="is_active" />
        <StyledSquareSwitch
            v-model="params.is_active"
            label="Square Switch Backwards Compatibility"
            color="secondary"
            name="is_active" />
        <StyledDatepicker
            v-model="params.datepicker"
            label="Date Picker Disabled Dates"
            min="2024-01-01"
            max="2024-01-15"
            color="secondary"
            date-picker-color="secondary"
            name="datepicker" />
        <StyledDatepicker
            v-model="params.datepicker2"
            label="Date Picker Range"
            view-mode="year"
            :range="true"
            name="datepicker2"
            @input="save" />
        <StyledTextArea
            v-model="params.textarea"
            label="Text Area"
            class="col-span-2"
            name="textarea" />
        <StyledSearch
            v-model="params.search"
            name="search"
            label="Styled Search" />
        <StyledSearch
            v-model="params.search"
            v-debounce:500ms="save"
            :debounce-events="['input']"
            name="search"
            label="Styled Search Debounce 500ms" />
        <StyledRadio
            v-model="params.radio"
            name="name"
            color="primary"
            radio-color="primary"
            inline
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            label="Radio Inline" />
        <StyledRadio
            v-model="params.radio"
            name="radio"
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            label="Radio" />
        <StyledFileSelector
            v-model="params.file"
            name="file"
            label="File Selector" />
        <StyledFileSelector
            v-model="params.file2"
            name="file"
            multiple
            counter
            label="Fancy File Selector Multiple">
            <template #selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                    <v-chip
                        size="small"
                        label
                        color="primary"
                        class="me-2">
                        {{ fileName }}
                    </v-chip>
                </template>
            </template>
        </StyledFileSelector>

        <StyledMask
            v-model="params.mask"
            name="mask"
            placeholder="###-###-####"
            :options="{ mask: '###-###-####' }"
            label="Phone Number Masked Input" />
        <StyledMask
            v-model="params.mask2"
            name="mask2"
            placeholder="A1A-1A1"
            :options="{ mask: '@#@-#@#' }"
            label="Postal Code Masked Input" />

        <StyledCurrency
            v-model="params.currency"
            color="primary"
            placeholder="0.00"
            name="currency"
            label="Currency" />

        <div class="flex gap-4 items-center">
            <div class="flex gap-4 items-center">
                Font Awesome Icon <FontAwesomeIcon icon="pencil" />
            </div>
            <div>VS</div>
            <div class="flex gap-4 items-center">
                <v-icon icon="mdi-pencil" /> Material Design Icon
            </div>
        </div>
        <StyledButton
            v-tooltip="'Tooltip example.'"
            type="submit">
            Full Width Button With Tooltip
        </StyledButton>
        <div>
            <StyledButton
                @click="openModal">Open Modal Example</StyledButton>
        </div>
    </form>

    <div class="flex flex-col sm:grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-12">
        <StyledButton color="primary">Button Primary</StyledButton>
        <StyledButton color="secondary">Button Secondary</StyledButton>
        <StyledButton color="success">Button Success</StyledButton>
        <StyledButton color="info">Button Info</StyledButton>
        <StyledButton color="warning">Button Warning</StyledButton>
        <StyledButton color="error">Button Error</StyledButton>
        <StyledButton color="accent">Button Accent</StyledButton>
    </div>
    <StyledCluster narrow>
        <StyledButton size="large" class="w-48" color="primary">Button Primary</StyledButton>
        <StyledButton size="large" color="secondary">Button Secondary</StyledButton>
        <StyledButton size="large" color="success">Button Success</StyledButton>
        <StyledButton size="large" color="info">Button Info</StyledButton>
        <StyledButton size="large" color="warning">Button Warning</StyledButton>
        <StyledButton size="large" color="error">Button Error</StyledButton>
        <StyledButton size="large" color="accent">Button Accent</StyledButton>
    </StyledCluster>
    <div class="flex flex-col sm:grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-12">
        <StyledButton size="large" class="w-48" color="primary">Button Primary</StyledButton>
        <StyledButton size="large" color="secondary">Button Secondary</StyledButton>
        <StyledButton size="large" color="success">Button Success</StyledButton>
        <StyledButton size="large" color="info">Button Info</StyledButton>
        <StyledButton size="large" color="warning">Button Warning</StyledButton>
        <StyledButton size="large" color="error">Button Error</StyledButton>
        <StyledButton size="large" color="accent">Button Accent</StyledButton>
    </div>

    <div class="font-weight-bold font-bold">
        <h1>Typography</h1>
        <h1>Title h1</h1>
        <h2>Title h2</h2>
        <h3>Title h3</h3>
        <h4>Title h4</h4>
        <h5>Title h5</h5>
        <h6>Title h6</h6>
        <p>Paragraph</p>
    </div>
    
</MainContentLayout>
</template>
