
<script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import LoginContainer from '@/components/Auth/LoginContainer';
import { router } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const props = defineProps({
    codeExpiry: {
        type: Number,
        default: 300
    },
    sentTo: {
        type: String,
        default: ''
    },
    resetting: {
        type: Boolean,
        default: false
    },
    initialSetup: {
        type: Boolean,
        default: false
    },
    errors: {
        type: Object,
        default: _ => ({})
    }
});
const code = ref('');
const suppressionDuration = ref(null);
const toast = useToast();

const userStore = useUserStore();
const { profile } = storeToRefs(userStore);

const suppressionOptions = computed(() => {
    return profile.value?.suppression_durations?.map(duration => ({
        label: duration > 0 ? `${duration} days` : 'Indefinitely',
        value: duration
    }));
});

const startCountdown = (counterRef, limit, finishedCallback = null) => {
    counterRef.value = limit;
    const interval = setInterval(() => counterRef.value--, 1000);
    setTimeout(() => {
        clearInterval(interval);
        if (finishedCallback) {
            finishedCallback();
        }
    }, limit * 1000);
};

// Validity countdown
let validFor = props.codeExpiry;
const stillValid = ref(true);
const validityCountdown = ref(0);

// Display time remaining as mm:ss
const validityDisplay = computed(() => {
    const minutes = Math.floor(validityCountdown.value / 60);
    const seconds = (validityCountdown.value % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
});

const startValidityCountdown = () => {
    stillValid.value = true;
    startCountdown(validityCountdown, validFor, stopValidityCountdown);
};

const stopValidityCountdown = () => {
    stillValid.value = false;
};

onMounted(startValidityCountdown);

// Resending countdown
const resendLimit = 20;
let resending = ref(false);
let resendCountdown = ref(0);

const stopResendCountdown = () => {
    resendCountdown.value = 0;
};

const resendCode = () => {
    resending.value = true;
    axios.post('/2fa/resend').then(_ => {
        validFor = 300;
        startValidityCountdown();
        startCountdown(resendCountdown, resendLimit, stopResendCountdown);
    }).finally(_ => {
        resending.value = false;
    });
};

const verify = async () => {
    const payload = {
        code: code.value,
        suppression_duration: suppressionDuration.value
    };
    await router.post('/2fa', payload, {
        onSuccess: () => {
            if (props.resetting) {
                toast.success('Password successfully set');
            } else if (props.initialSetup) {
                toast.success('Two factor authentication successfully set up');
            }
        },
        onError: (errors) => {
            if (errors.status == 429) {
                toast.error('Too many attempts. Please try again later.');
                router.visit('/login');
            } else {
                toast.error(errors?.code || 'An error occurred');
            }
        },
    });
};

</script>
<template>
<LoginContainer>
    <form @submit.prevent="verify">
        <template v-if="stillValid">
            <div class="grid grid-cols-1 gap-4 mt-5">
                <div>
                    Please enter the verification code sent to {{ props.sentTo }}
                </div>

                <vOtpInput
                    v-model="code"
                    name="code"
                    variant="solo"
                    autocomplete="off" />

                <div class="text-sm -mt-3">
                    Time remaining: {{ validityDisplay }}
                </div>
                <StyledDropdown
                    v-if="suppressionOptions?.length"
                    v-model="suppressionDuration"
                    :options="suppressionOptions"
                    name="suppression_duration"
                    label="Don't ask again for" />
            </div>

            <div class="flex flex-col">
                <StyledButton
                    :full="true"
                    type="submit"
                    class="mb-4">Verify</StyledButton>
            </div>

            <div class="text-sm pt-3 border-t border-t-grey-500">
                <template v-if="resending">
                    Resending...
                </template>
                <template v-else-if="resendCountdown > 0">
                    Code resent. Still haven't received it? You'll be able to request it again in {{ resendCountdown }} seconds
                </template>
                <template v-else>
                    <div>Didn't receive the verification code?</div>
                    <StyledButton class="mt-2" @click="resendCode">Resend Code</StyledButton>
                </template>
            </div>
        </template>
        <div v-else class="flex flex-col space-y-4">
            <div>The verification code has expired. Please request a new one.</div>
            <StyledButton full @click="resendCode">Resend Code</StyledButton>
        </div>
    </form>
</LoginContainer>
</template>
