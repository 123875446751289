<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';

const props = defineProps({
    user: {
        type: Object,
        default: () => ({
            role: null,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.user });

const toast = useToast();

const { roles } = useOptionsStore();

const userStore = useUserStore();
const { storeUser, updateUser } = userStore;

if (props.user.hash && props.user.role?.value) {
    const matchedRole = roles.find(role => role.name === props.user.role.value);
    if (matchedRole) form.value.role = matchedRole.name;
}

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeUser : updateUser;
        const toastMessage = props.mode === 'Create' ? 'User Created' : 'User Updated';

        await action({
            ...form.value,
            hash: props.user.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        toast.error('An unexpected error occurred');
    }
};
</script>
<template>
<ModalWrapper>
    <template #header>{{ mode }} User</template>
    <template #subheader>Create or edit a user</template>

    <form @submit.prevent="save">
        <div class="grid md:grid-cols-2 gap-x-4 gap-y-3">
            <StyledInput
                v-model="form.first_name"
                name="first_name"
                placeholder="First Name"
                label="First Name" />
            <StyledInput
                v-model="form.last_name"
                placeholder="Last Name"
                name="last_name"
                label="Last Name" />
            <StyledInput
                v-model="form.email"
                name="email"
                placeholder="example@mail.com"
                label="Email" />
            <StyledMask
                v-model="form.phone_number"
                :mask="['###-###-####']"
                label="Phone Number"
                name="phone"
                placeholder="###-###-####" />
            <StyledDropdown
                v-model="form.role"
                item-title="display_name"
                item-value="name"
                :options="roles"
                name="role"
                placeholder="Please Select"
                label="Account Type" />
            <StyledRoundSwitch
                v-model="form.send_password"
                label="Email Password Setup Request"
                inset
                name="send_password"
                @input="form.set_password = false" />
            <StyledRoundSwitch
                v-model="form.set_password"
                :label="mode === 'Edit' ? 'Reset Password' : 'Set Password'"
                inset
                name="set_password"
                @input="form.send_password = false" />
        </div>

        <div v-if="form.set_password" class="grid md:grid-cols-2 gap-x-4">
            <StyledPassword
                v-model="form.password"
                name="password"
                placeholder="********"
                label="Password" />
            <StyledPassword
                v-model="form.password_confirmation"
                name="password_confirmation"
                placeholder="********"
                label="Password Confirmation" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
